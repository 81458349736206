import { Inject, Injectable } from '@angular/core';
import ApiService from '../api.service.abstract';
import { APP_CONFIG } from '@pulpo/app-config';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { RouteReuseStrategy } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class DataStorageService extends ApiService<any> {
  constructor(
    @Inject(APP_CONFIG) environment: any,
    protected http: HttpClient,
    routeReuse: RouteReuseStrategy
  ) {
    super(environment, http, 'data-storage/api/min-io', routeReuse);
    if (environment.name === 'localDev') {
      this.url = 'http://localhost:8093';
    }
  }

  getBookingFileByName(fileName: string): Observable<HttpResponse<string>> {
    return this.http.get(`${this.url}/${this.endpoint}/get-resource`, {
      params: {
        bucket: 'BOOKING',
        objectName: fileName,
      },
      observe: 'response',
      responseType: 'text',
    });
  }
}
